import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router' // 引入vue-router
import '../static/res/js/rem'

import Me from '@/views/Me'
import Login from '@/views/Login'
import Yinge from '@/views/Yinge'
import Meituan from '@/views/Meituan'
import CZList from '@/views/CZList'
import CZListKW from '@/views/CZListKW'

import Recharge from '@/views/Recharge'
import FilmCoupon from '@/views/FilmCoupon'
import Register from '@/views/Register'
import Act from '@/views/Act'
import Qm from '@/views/Qm'
import QmList from '@/views/QmList'
import List2 from '@/views/List2'
import List from '@/views/List'
import Products from '@/views/Products'
import Products2 from '@/views/Products2'
import Activate from '@/views/Activate'
import ActivateInfo from '@/views/ActivateInfo'
import Actqy from '@/views/Actqy'

import LingkaNew from '@/views/LingkaNew'
import Lingka from '@/views/Lingka'
import Faka from '@/views/Faka'
import Auth from '@/views/Auth'
import Pay from '@/views/Pay'
import HwPay from '@/views/HwPay'
import PayDemo from '@/views/PayDemo'
import Order from '@/views/Order'
import QmOrder from '@/views/QmOrder'
// import Orderxb from '@/views/Orderxb'
import Item from '@/views/Item'
import Home from '@/views/Home'
import Kfc from '@/views/Kfc'
import Chongzhi from '@/views/Chongzhi'

// import Xbcz from '@/views/Xbcz'
import QRcode from '@/views/QRcode'
import Smshistory from '@/views/Smshistory'
import Starbucks from '@/views/Starbucks'
import ExpCards from '@/views/ExpCards'
import urlParams from './utils/UrlParams'
import hhstore from './utils/HHStore'
import help from './utils/Help'
import aes from './utils/Aes'

Vue.config.productionTip = false
Vue.config.ignoredElements = [
	'wx-open-launch-weapp'
]

// if(window.location.host == 'xb.123zhs.com' && window.location.pathname == '/'){
// 	window.location.href = window.location.origin + '/xbcz';
// 	throw SyntaxError();
// }

Vue.use(VueRouter) //Vue全局使用Router
// Vue.use(Toast);

let ch = urlParams.get('ch') ? urlParams.get('ch') : urlParams.get('card_type')
if (!ch && hhstore.get('card_type')) {
	ch = hhstore.get('card_type')
}

let producttitle = "权益王"
switch (ch) {
	case "qyw":
		producttitle = "权益王";
		break
	case "nnl":
		producttitle = "demo";
		break
	case "suka":
		producttitle = "速卡科技";
		break
	case "suba":
		producttitle = "速8权益";
		break
	case "duolami":
		producttitle = "哆啦觅";
		break
	case "mamajia":
		producttitle = "嫲嫲家";
		break
	case "dingdang":
		producttitle = "叮当";
		break
	case "ywzx":
		producttitle = "因为甄选";
		break
	case "dianfeng":
		producttitle = "芜湖巅峰街舞";
		break
	case "xixiyoupin":
		producttitle = "时宜志";
		break
	case "shihuituan":
		producttitle = "十荟团";
		break
	case "daijinquan":
		producttitle = "代金券外卖券";
		break
	case "weizhegou":
		producttitle = "微折购";
		break
	case "sangzi":
		producttitle = "桑梓生活省钱助手";
		break
	case "mitao":
		producttitle = "蜜淘星球";
		break
	case "miaojielong":
		producttitle = "喵接龙";
		break
	case "lijiandawang":
		producttitle = "立减大王";
		break
	case "darenkezan":
		producttitle = "达人客赞";
		break
	case "quanyidashi":
		producttitle = "权益大师";
		break
	case "yatuan":
		producttitle = "呀团";
		break
	case "tanhui":
		producttitle = "探惠生活";
		break
	case "fanmeng":
		producttitle = "凡梦珍汐";
		break
	case "yichen":
		producttitle = "一尘联盟";
		break
	case "mangguo":
		producttitle = "芒果严选";
		break
	case "xiangkaixin":
		producttitle = "享开心权益中心";
		break
	case "Q8USdphPCMw2":
		producttitle = "我要打折";
		break
	case "dgybee":
		producttitle = "卓越会员权益";
		break
	case "yhc":
	case "yhc_xb":
	case "yhc_zjly":
	case "yhc1":
	case "yhc11":
	case "yhc12":
	case "yhc13":
	case "yhc14":
	case "yhc15":
	case "yhc_yyh":
	case "kdw":
	case "xueer":
		producttitle = "权益中心";
		break
	case "hzdxbk":
	case "hzdxc":
	case "hzdnx":
	case "hzdkfc":
	case "hzdmdl":
	case "hzdall":
		producttitle = "狠值得";
		break
	case "kfc":
	case "dianying":
	case "waimai":
	case "xbk":
	case "rx":
	case "mdl":
		producttitle = "一鹿学";
		break
	case "qymkfc":
	case "qymall":
		producttitle = "权益猫";
		break
	default:
		producttitle = "权益中心";
}

const routes = [{
	path: '/list2',
	component: List2,
	meta: {
		title: producttitle,
		showTab: true
	}
},
{
	path: '/qm',
	component: Qm,
	meta: {
		title: '券码',
		// showTab: true
	}
},
{
	path: '/qmlist',
	component: QmList,
	meta: {
		title: '券码',
		// showTab: true
	}
},
{
	path: '/',
	component: List,
	meta: {
		title: producttitle,
		showTab: true
	}
},
// {
// 	path: '/xbcz',
// 	component: Xbcz,
// 	meta: {
// 		title: '',
// 	}
// },
{
	path: '/login',
	component: Login,
	meta: {
		title: '登录'
	}
},
{
	path: '/expcards',
	component: ExpCards,
	meta: {
		title: '领取和赠送'
	}
},
{
	path: '/me',
	component: Me,
	meta: {
		title: '我的',
		showTab: true
	}
},
{
	path: '/register',
	component: Register,
	meta: {
		title: '激活'
	}
},
{
	path: '/act',
	component: Act,
	meta: {
		title: '激活'
	}
},
{
	path: '/yinge',
	component: Yinge,
	meta: {
		title: ''
	}
},
{
	path: '/meituan',
	component: Meituan,
	meta: {
		title: ''
	}
},
{
	path: '/faka',
	component: Faka,
	meta: {
		title: '发卡'
	}
},
{
	path: '/smshistory',
	component: Smshistory,
	meta: {
		title: '短信历史记录'
	}
},
{
	path: '/lingka',
	component: Lingka,
	meta: {
		title: '领卡'
	}
},
{
	path: '/lingkanew',
	component: LingkaNew,
	meta: {
		title: '领卡'
	}
},
{
	path: '/auth',
	component: Auth,
	meta: {
		title: '验证'
	}
},
{
	path: '/pay',
	component: Pay,
	meta: {
		title: '支付'
	}
},
{
	path: '/paydemo',
	component: PayDemo,
	meta: {
		title: '支付'
	}
},
{
	path: '/hwpay',
	component: HwPay,
	meta: {
		title: '支付'
	}
},
{
	path: '/order',
	component: Order,
	meta: {
		title: '我的订单'
	}
},
{
	path: '/qmorder',
	component: QmOrder,
	meta: {
		title: '我的订单'
	}
},

// {
// 	path: '/orderxb',
// 	component: Orderxb,
// 	meta: {
// 		title: '我的订单'
// 	}
// },
{
	path: '/item',
	component: Item,
	meta: {
		title: '在线充值'
	}
},
{
	path: '/home',
	component: Home,
	meta: {
		title: '一鹿学'
	}
},
{
	path: '/products',
	component: Products,
	meta: {
		title: producttitle,
		showTab: true
	}
},
{
	path: '/products2',
	component: Products2,
	meta: {
		title: producttitle,
	}
},
{
	path: '/activate',
	component: Activate,
	meta: {
		title: '会员激活',
	}
},
{
	path: '/actqy',
	component: Actqy,
	meta: {
		title: '权益王卡',
	}
},
{
	path: '/activateinfo',
	component: ActivateInfo,
	meta: {
		title: '会员激活',
	}
},

{
	path: '/kfc',
	component: Kfc,
	meta: {
		title: '肯德基'
	}
},
{
	path: '/duihuan',
	component: Chongzhi,
	meta: {
		title: '兑换权益'
	}
},

{
	path: '/starbucks*',
	component: Starbucks,
	meta: {
		title: '查看券码'
	}
},
{
	path: '/chongzhi',
	component: CZList,
	meta: {
		title: '特惠充值'
	}
},
{
	path: '/chongzhikw',
	component: CZListKW,
	meta: {
		title: '特惠充值'
	}
},

{
	path: '/recharge',
	component: Recharge,
	meta: {
		title: '特惠充值'
	}
},
{
	path: '/filmcoupon',
	component: FilmCoupon,
	meta: {
		title: '免费领取电影票优惠券'
	}
},

{
	path: '/qrcode',
	component: QRcode,
	meta: {
		title: '特惠充值'
	}
},

]

const router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach((toRoute, fromRoute, next) => {
	window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : '';
	help.getdot()
	next();
})

{
	// 处理小程序过来的加密数据
	const hzdmsg = urlParams.get('hzdmsg')
	if (hzdmsg) {
		let data = aes.decrypt_mini(hzdmsg, "UB2UJDwygrW99kdC", "aN7iUYD4rfiR2CyR")
		const arr = data.split('.')

		// card_type要放第一个，hhstore做了特殊处理的
		arr['4'] && hhstore.set("card_type", arr['4'])
		arr['0'] && hhstore.set("phone", arr['0'])
		arr['1'] && hhstore.set("token", arr['1'])
		arr['2'] && hhstore.set("uid", arr['2'])
		arr['3'] && hhstore.set("mini_type", arr['3'])
	}
}


new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')



// 在开发模式下，不执行某段逻辑
// if (process.env.NODE_ENV === 'production') {
//   setInterval(function() {
//     var startTime = new Date();
//     debugger;
//     if (new Date() - startTime > 50) {
//       stopExecution();
//     }
//   }, 500);
// }

// function stopExecution() {
//   window.stop();
// }